import * as React from "react";
import OurTeamPageComponent from "src/components/pages/about-us/our-team";

const OurTeamPage = ({ location }) => {
  const copy = {
    members: [
      {
        name: "Jonathan Spetner",
        text: `Jonathan grew up in St. Louis, MO and returned there in 1986,
                after a successful business career in New York City, to join
                his father in the family insurance business. Jonathan focuses
                on all aspects of life insurance and uses life insurance for
                estate, business, and tax planning.`,
      },
      {
        name: "Aaron Spetner",
        text: `Aaron is involved in all aspects Information Technology for the
                Spetner family insurance business. Aaron successfully designed
                and implemented the “BenManage” employee benefit portal used by
                employees to hire employees and enroll them in their insurance
                benefits. Aaron lives with his wife and two children in Beitar
                Illit, Israel.`,
      },
      {
        name: "Judah Spetner",
        text: `Judah joined the family business in 2010 and focuses on the
        insurance benefits bought by employees through payroll deduction at the
        worksite. Judah lives with his wife and three children in Cincinnati, OH.`,
      },
    ],
  };
  return (
    <OurTeamPageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Our Team"
    />
  );
};

export default OurTeamPage;

import * as React from "react";
import Layout from "src/components/layout";
import jspetner from "src/images/jspetner.jpg";
import ajspetner from "src/images/ajspetner.jpg";
import juspetner from "src/images/juspetner.jpg";

const OurTeamPageComponent = ({ copy, pageTitle, location, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="container inner-content w-full mx-auto lg:flex lg:flex-row lg:flex-wrap gap-10 my-20 text-center justify-center">
        {copy.members.map((item, key) => (
          <article
            key={key}
            className="w-5/6 mx-auto my-8 lg:my-0 lg:w-5/12 bg-white rounded-3xl overflow-hidden flex flex-col space-y-4  shadow-xl
          before:bg-blue-pale before:w-32 before:h-32 before:block before:mx-auto before:top-0 before:-mt-20 before:rounded-full
          after:bg-blue-pale after:w-32 after:h-32 after:block after:mx-auto after:bottom-0 after:-mb-20 after:rounded-full"
          >
            <div>
              <img
                className="mx-auto mt-8 mb-4"
                alt={item.name}
                src={[jspetner, ajspetner, juspetner][key]}
              />
            </div>
            <h2 className="mt-8">{item.name}</h2>
            <p className="flex-1 leading-7 mx-14">{item.text}</p>

            <div>&nbsp;</div>
          </article>
        ))}
      </section>
    </Layout>
  );
};

export default OurTeamPageComponent;
